<template>
<div class="location-permission full-screen">
    <img src="../assets/images/illustrations/gps.png" />
    <div class="location-permission__content">
        <h1>{{ $t('LocationPermission.title') }}</h1>
        <p>
            {{ $t('LocationPermission.message') }}
        </p>
    </div>

    <pty-button light block :loading="loading" @click="next">{{ $t('Common.consent') }}</pty-button>
</div>
</template>

<script>
import PtyButton from '../components/PtyButton.vue';
export default {
    name: 'LocationPermission',
    components: {
        PtyButton
    },
    data: () => ({
        loading: false
    }),

    methods: {
        async goToCameraPEX() {
            try {
                const cameraPex = await navigator.permissions.query({
                    name: 'camera'
                });
                if (cameraPex.state !== 'granted') {
                    this.$router.push({
                        name: 'CameraPermission'
                    });
                    return;
                }

                // go to OnBoarding
                this.$router.push({
                    name: 'OnBoarding'
                });
            } catch (error) {
                console.error('Error:', error);
                this.$router.push({
                    name: 'CameraPermission'
                });
            }
        },

        async next() {
            this.loading = true;
            // obtain location permission
            window.navigator.geolocation.getCurrentPosition((d) => {
                console.log(d);
                setTimeout(() => {
                    this.loading = false;
                    this.goToCameraPEX();
                }, 1000);
            }, () => {

                setTimeout(() => {
                    this.loading = false;

                    this.$vs.notification({
                        title: this.$t('Common.error'),
                        text: this.$t('LocationPermission.error'),
                        position: 'top-center',
                        border: 'primary',
                    });

                    this.goToCameraPEX();
                }, 1000);
            });
        }
    }
}
</script>

<style scoped>
.location-permission {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    background: var(--Linear, linear-gradient(12deg, #00A9C0 0.95%, rgba(0, 169, 192, 0.63) 101.3%));

}

.location-permission__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
}

h1 {
    color: #FFF;
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.0425rem;
    margin: 0;
}

p {
    color: #FFF;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 177.1%;
    /* 1.88169rem */
}
</style>
